<template>
  <div class="reservation-cancel">
    <div class="reservation-cancel--content">
      <p>
        Clicking Start will send an email to the customer to collect more
        information.
        <br />
        <br />
        Further action will be required to fully cancel the reservation.
      </p>
      <ClassificationSelector
        id="reservation-cancellation-classification-selector"
        ref="classification-selector"
        v-model="classificationId"
        :classification-type-id="2"
      />
      <CRTextArea
        id="reservation-cancellation-notes-text-area"
        v-model="notes"
        placeholder="Record information about the cancellation here."
        auto-grow
        hide-details
      />
      <v-checkbox
        id="reservation-cancellation-send-email-checkbox"
        v-model="sendEmail"
        :disabled="disableSendEmail"
        class="shrink mr-2"
        label="Send Cancellation Email to Customer"
        color="primary"
        solo
        hide-details
      ></v-checkbox>
    </div>
    <div class="cr-sidebar-dialog--btn-spacer"></div>
    <div>
      <CRButton
        v-if="!loading"
        id="reservation-cancellation-cancel-button"
        class="reservation-cancel--action-btn"
        color="primary"
        :disabled="!isSubclassificationSelected"
        @click="submitForm"
      >
        Start
      </CRButton>
      <CRProgressCircular v-else :size="27" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ClassificationSelector from '@/components/ClassificationSelector.vue'
import { EventBus } from '@/utils/event-bus'
import ticket from '@/services/ticket'

const CHARTERUP_INITIATED_CANCELLATION_CLASSIFICATION_ID = 16
const CLIENT_INITIATED_CANCELLATION_CLASSIFICATION_ID = 17

export default {
  components: {
    ClassificationSelector,
  },
  props: {},
  data() {
    return {
      notes: '',
      loading: false,
      sendEmail: true,
      disableSendEmail: false,
      classificationId: null,
      isSubclassificationSelected: false,
    }
  },
  computed: {
    ...mapState('reservations', {
      reservationsCache: (state) => {
        return state.reservationsCache
      },
    }),
    reservationId() {
      return this.$route.params.id
    },
    reservation() {
      return this.reservationsCache[this.reservationId]
    },
  },
  watch: {
    classificationId() {
      if (
        this.classificationId == CLIENT_INITIATED_CANCELLATION_CLASSIFICATION_ID
      ) {
        this.sendEmail = true
        this.disableSendEmail = false
      } else if (
        this.classificationId ==
        CHARTERUP_INITIATED_CANCELLATION_CLASSIFICATION_ID
      ) {
        this.sendEmail = false
        this.disableSendEmail = true
      } else if (this.classificationId == null) {
        this.disableSendEmail = false
      }
      this.isSubclassificationSelected =
        this.classificationId &&
        this.classificationId !=
          CLIENT_INITIATED_CANCELLATION_CLASSIFICATION_ID &&
        this.classificationId !=
          CHARTERUP_INITIATED_CANCELLATION_CLASSIFICATION_ID
    },
  },
  methods: {
    close() {
      this.$store.dispatch('app/closeDialog') // TODO: make this vuex's concern. Put in 'reservations'.
    },
    async submitForm() {
      this.loading = true
      const isValid = this.$refs['classification-selector'].validate()
      if (!isValid) {
        this.loading = false
        return
      }

      await this.$store.dispatch('reservations/cancelReservation', {
        reservationId: this.reservation.reservationId,
        sendEmail: this.sendEmail,
        classificationId: this.classificationId,
      })

      this.$store.dispatch('app/showAlert', {
        color: 'success',
        message: 'Cancellation has been started.',
      })

      const user = this.$store.getters['auth/currentUser']
      await ticket.create({
        payload: {
          assignedToId: user.userId,
          comments: this.notes,
          companyId: user.companyId,
          createdById: user.userId,
          reservationId: this.reservation.reservationId,
          ticketSeverityTypeId: 2,
          ticketStatusTypeId: 1,
          ticketTypeId: 1,
          title: `Cancellation - ${this.reservation.reservationId}`,
          classificationId: this.classificationId,
          subTickets: [],
        },
      })

      this.$store.dispatch(
        'app/showAlert',
        { message: 'A ticket has been created.' },
        { root: true }
      )
      EventBus.$emit('refresh-detail')
      EventBus.$emit('refresh-tickets')
      EventBus.$emit('refresh-reservation-detail')
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-cancel {
  height: 100%;
  width: 500px;

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }

  &--content {
    flex: 1;
    margin: 20px 48px;
  }
}
</style>
